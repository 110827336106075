import React from 'react';
import styles from './Card.module.css';

const Card = ({ id, image, isFlipped, onClick, imageVerso }) => {
  return (
    <div className={`${styles.card} ${isFlipped ? styles.flipped : ''}`} onClick={() => onClick(id)}>
      <div className={styles.inner}>
        <div className={styles.front}>
          <img className={styles.cartaImg} src={imageVerso} alt="Verso" />
        </div>
        <div className={styles.back}>
          <img className={styles.cartaImg} src={image} alt="Card" />
        </div>
      </div>
    </div>
  );
};

export default Card;
