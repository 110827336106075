import React from "react";
import styles from "./HomeGame.module.css"
import Login from "../Login/Login"

function HomeGame() {

  return (
    <div> 
        <div className={styles.HomeGame}>
          <main className={styles.HomeGameBody}>
              <Login/>
          </main>
        </div>
    </div>
  );
}

export default HomeGame;
