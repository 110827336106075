import { React, Image } from "react";
import styles from "./Home.module.css";
import BannerHome from "./BannerHome/BannerHome";
import Typical from "react-typical";
import { Link } from "react-router-dom";
import Ferramentas from "./Ferramentas/Ferramentas";

const Home = () => {
  return (
    <section className={styles.homeContainer}>
      <Typical
        className={styles.h1}
        steps={[1000, "Fohattech"]}
        loop={Infinity}
        wrapper="h1"
      />

  <div className={styles.containerH2}>
    <h2>Fohattech é um site que oferece uma ampla variedade de produtos afiliados, além de disponibilizar ferramentas gratuitas.</h2>
  </div>

      <container className={styles.containerFD}>
        <p className={styles.textoFD}>Ferramentas disponíveis!</p>
        <div className={styles.cantainerSeta}>
          <p className={styles.seta}>☟</p>
        </div>
        <di className={styles.containerIMC}>
        <Ferramentas/>
          
          {/* ================================================= */}
          {/* ==ADS======ADS=======ADS=======ADS==========ADS== */}
          {/* ================================================= */}
          {/* ================================================= */}

          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3074013886457000"
     crossorigin="anonymous"></script>

          <ins class={styles.adsbygoogle}
              data-ad-client="ca-pub-3074013886457000"
              data-ad-slot="8941972203"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
          <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
          </script>

          {/* ================================================= */}
          {/* ==ADS======ADS=======ADS=======ADS==========ADS== */}
          {/* ================================================= */}
          {/* ================================================= */}



        </di>
      </container>
    </section>
  );
};

export default Home;
