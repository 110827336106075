import React, { Component } from "react";
import Slider from "react-slick";
import styles from './Vitrine.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default class SimpleSlider extends Component {


  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      rows: 1,
      initialSlide: 0,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };


  


    const items = [
      {
        "id": 0,
        "img": "/images/newFotoNutricao.jpeg",
        "name": "Nutrição Aplicada ao Esporte",
        "valuePricePerX": 5.51,
        "pricePerX": "até 6x de",
        "price": 29.90,
        "description": 'Ajuda você a compreender os conceitos da nutrição esportiva...',
        "shoppingLink": "https://sun.eduzz.com/405007?a=77701451"
      },
      {
        "id": 1,
        "img": "images/gooffy-box.png",
        "name": "GOOFFY BOX",
        "valuePricePerX": 49.90,
        "pricePerX": "até 12x de",
        "price": 497,
        "description": 'Tenha na sua casa centenas de canais, filmes e séries ilimitados sem mensalidade.',
        "shoppingLink": "http://mon.net.br/2717i7"
      },
      {
        "id": 2,
        "img": "images/liftDetox.png",
        "name": "lift detox",
        "valuePricePerX": 9.74,
        "pricePerX": "até 12x de",
        "price": 97,
        "description": 'Emagrecer da maneira rápida e natural',
        "shoppingLink": "https://app.monetizze.com.br/r/AED21303786"
      },
      {
        "id": 2,
        "img": "images/liftDetox.png",
        "name": "lift detox",
        "valuePricePerX": 9.74,
        "pricePerX": "até 12x de",
        "price": 97,
        "description": 'Emagrecer da maneira rápida e natural',
        "shoppingLink": "https://app.monetizze.com.br/r/AED21303786"
      },
      {
        "id": 2,
        "img": "images/liftDetox.png",
        "name": "lift detox",
        "valuePricePerX": 9.74,
        "pricePerX": "até 12x de",
        "price": 97,
        "description": 'Emagrecer da maneira rápida e natural',
        "shoppingLink": "https://app.monetizze.com.br/r/AED21303786"
      }
      
    ];

    return (
      <section className={styles.vitrineContainer}>
        <Slider {...settings}>

          {items.map((item, key) => {
            return <div className={styles.containerShelf} key={key}>
              <div className={styles.shelf}>
                <img className={styles.img} src={item.img} alt={`${item.name}img`} />
                <h3 className={styles.name}>{item.name}</h3>
                <p className={styles.pricePerX}>{item.pricePerX} R$: <strong className={styles.valuePricePerX}>{item.valuePricePerX.toFixed(2).replace(".", ",")}</strong></p>
                <p className={styles.boxPrice}>R$: <strong>{item.price.toFixed(2).replace(".", ",")}</strong> à vista</p>
                <div className={styles.boxDescriptionVisible}>
                  <p className={styles.description}>{item.description}</p>
                </div>
                <a className={styles.button} href={item.shoppingLink}>Comprar</a>
              </div>
            </div>
          })}

        </Slider>
      </section>
    );
  }
}

