export function CreatingAffiliateBlock() {
 
        return(`

        <div class="Block-containerAFDs">
          <h3 class="class-cursos-h3">Quer obter mais conhecimento sobre o universo?</h3>
          <p class="class-esplicando">Aqui está uma lista de conteúdos que pode ajudar você!</p>
          <div class="container-itens">
          
                <a href="https://go.hotmart.com/L89676324N"> 
                    <div class="card-item">
                      <img src="https://static-media.hotmart.com/obx_o4zVDJ-jwcb0cY-3mY-86X8=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/9b2da8d3-e0df-468a-b9e0-680d29bfc5f6/foto_gravacao.jpg?w=920"/>
                      <p>Astronomia Fundamental</p>
                    </div>
                </a>
          
                <a href="https://go.hotmart.com/Q89676974J"> 
                    <div class="card-item">
                      <img src="https://static-media.hotmart.com/dI9DXnN6OiUG-C4bUg8lzTDURtI=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/45c31089-d829-4700-94c7-e1ab4a89100d/Designsemnome20220912T134020777.png?w=920"/>
                      <p>Orbitando O Sistema Solar</p>
                    </div>
                </a>
          
                <a href="https://go.hotmart.com/M89677050G">
                    <div class="card-item">
                      <img src="https://static-media.hotmart.com/PmqxVyk8-IqZDNkctHDKRgxMmnk=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/b01cb133-f575-4fcc-b8bf-9d54d9a1affe/ebook.png?w=920"/>
                      <p>O guia pratico da ufologia</p>
                    </div>
                </a>
          
                <a href="https://go.hotmart.com/F89677003P">
                    <div class="card-item">
                      <img src="https://static-media.hotmart.com/aUVz8-b0O-SFChCEgoQKZT-xpcI=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/5552b2c8-d421-4f0e-ab73-1d74b1506862/cinco.png?w=920"/>
                      <p>viagem pelo infinito espaço</p>
                    </div>
                </a>
          
                <a href="https://go.hotmart.com/X89681008F">
                    <div class="card-item">
                      <img src="https://static-media.hotmart.com/RbuNNhm0Ia491_faKmnkZJK-2F4=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/72b19fd4-893d-4836-af3a-95baeec8d10d/CapaINTRODUCAOAASTRONOMIAAMADORAEASTROFOTOGRAFIA1.png?w=920"/>
                      <p>INTRODUÇÃO À ASTRONOMIA...</p>
                    </div>
                </a>
          
              <a href="https://go.hotmart.com/O89679885O">
                  <div class="card-item">
                    <img src="https://static-media.hotmart.com/8BaD27bZXyJC2ds0Vpw1LU1xA2I=/filters:background_color(white)/hotmart/product_pictures/3658f68f-ed73-4347-8e3d-d17d0b3fa3a1/20230522_153613_0000.png"/>
                    <p>Descomplicando a Astronomia</p>
                  </div>
              </a>
          
          </div>
    </div>    
    `); 
}