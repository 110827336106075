import React, { createContext, useContext, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';

const UserContext = createContext(null);
const SECRET_KEY = "minha_chave_secreta";

export const UserProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem('users');
    if (userData) {
      const bytes = CryptoJS.AES.decrypt(userData, SECRET_KEY);
      const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
      const decryptedUsers = JSON.parse(decryptedString);
      setUsers(Array.isArray(decryptedUsers) ? decryptedUsers : []);
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(users) && users.length) {
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(users), SECRET_KEY).toString();
      localStorage.setItem('users', encryptedData);
    } else {
      localStorage.removeItem('users');
    }
  }, [users]);

  const addUser = (newUser) => {
    setUsers(prevUsers => [...prevUsers, newUser]);
    setCurrentUser(newUser); // Set current user on registration
  };

  const login = (nome, senha) => {
    const user = users.find(user => user.nome === nome && user.senha === senha);
    if (user) {
      setCurrentUser(user);
      return true;
    }
    return false;
  };

  const logout = () => {
    setCurrentUser(null);
  };

  return (
    <UserContext.Provider value={{ currentUser, addUser, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);