import React, { useState, useEffect } from 'react';
import Carta from './Cartas/Card';
import CartasPeixes from './Cartas/CartasPeixes';
import styles from './Jogo.module.css';
import imageVerso from './Cartas/imgs/verso.jpg';
import FimDeFase from './FimDeFase/FimDeFase';

const Jogo = () => {
  const [cartas, setCartas] = useState([]);
  const [cronometro, setCronometro] = useState(0);
  const [tentativas, setTentativas] = useState(0);
  const [faseAtual, setFaseAtual] = useState(1);
  const [fimDeFase, setFimDeFase] = useState(false);
  const [passouDeFase, setPassouDeFase] = useState(false);
  const [virando, setVirando] = useState(false);
  const [mostrarPopup, setMostrarPopup] = useState(false);
  const [primeiraRodada, setPrimeiraRodada] = useState(true);

  const cartasPorFase = [4, 6, 8, 10, 12, 14, 16];

  useEffect(() => {
    iniciarJogo();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCronometro((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (tentativas > 0 && cronometro > 0 && !primeiraRodada) {
      setMostrarPopup(true);
    }
  }, [tentativas, cronometro, primeiraRodada]);

  useEffect(() => {
    if (cartas.filter(carta => !carta.matched).length === 0 && !primeiraRodada) {
      setTimeout(() => {
        setFimDeFase(true);
        setPassouDeFase(tentativas >= 5 && cronometro >= 6);
      }, 500);
    }
  }, [cartas, cronometro, tentativas, primeiraRodada]);

  const iniciarJogo = () => {
    const numeroCartas = cartasPorFase[faseAtual - 1];
    const imagensSelecionadas = CartasPeixes.slice(0, numeroCartas / 2);
    const cartasGeradas = [];
    for (let i = 0; i < numeroCartas; i++) {
      const imagem = imagensSelecionadas[i % imagensSelecionadas.length].imagem;
      cartasGeradas.push({ id: i, image: imagem, isFlipped: false, matched: false });
    }
    setCartas(shuffle(cartasGeradas));
  };

  const handleCardClick = (id) => {
    if (virando) return;
    setVirando(true);
    setCartas((prevCartas) =>
      prevCartas.map((carta) =>
        carta.id === id ? { ...carta, isFlipped: true } : carta
      )
    );
    setTimeout(() => {
      setTentativas((prev) => prev + 1);
      setVirando(false);
      verificarMatch(id);
    }, 500);
  };

  const verificarMatch = (id) => {
    const cartaSelecionada = cartas.find(carta => carta.id === id);
    const cartaVirada = cartas.find(carta => carta.isFlipped && !carta.matched);
    if (cartaVirada && cartaVirada.id !== cartaSelecionada.id) {
      if (cartaSelecionada.image === cartaVirada.image) {
        setCartas((prevCartas) =>
          prevCartas.map((carta) =>
            carta.id === id || carta.id === cartaVirada.id
              ? { ...carta, isFlipped: true, matched: true }
              : carta
          )
        );
      } else {
        setTimeout(() => {
          setCartas((prevCartas) =>
            prevCartas.map((carta) =>
              carta.isFlipped ? { ...carta, isFlipped: false } : carta
            )
          );
        }, 1000);
      }
    }
    if (primeiraRodada) {
      setPrimeiraRodada(false);
    }
  };

  const proximaFase = () => {
    setFaseAtual((prev) => prev + 1);
    setFimDeFase(false);
    setTentativas(0);
    setCronometro(0);
    setPrimeiraRodada(true);
    iniciarJogo();
  };

  const shuffle = (array) => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  return (
    <div className={styles.jogoContainer}>
      <div className={styles.infoPanel}>
        <div>
          <p>Cronômetro: {cronometro} s</p>
          <p>Tentativas: {tentativas}</p>
          <p>Fase Atual: {faseAtual}</p>
        </div>
      </div>
      <div className={styles.containerCartas}>
        {cartas.map((carta) => (
          <Carta
            key={carta.id}
            id={carta.id}
            image={carta.image}
            isFlipped={carta.isFlipped}
            onClick={() => handleCardClick(carta.id)}
            imageVerso={imageVerso}
            matched={carta.matched}
            style={{ opacity: carta.matched ? 0.5 : 1 }} // Adiciona transparência às cartas matched
          />
        ))}
      </div>
      {fimDeFase && (
        <FimDeFase
          tentativas={tentativas}
          cronometro={cronometro}
          passou={passouDeFase}
          proximaFase={proximaFase}
          reiniciarFase={() => setFimDeFase(false)}
        />
      )}
    </div>
  );
};

export default Jogo;
