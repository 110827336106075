import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styles from "./Login.module.css";
import eyeOpen from '../icons/olhos-aberto.png';
import eyeClosed from '../icons/olhos-fechado.png';
import RotatingImage from '../RotatingImage/RotatingImage';
import ImageRotatoria from '../icons/logo.png';

const Login = () => {
  const [nome, setNome] = useState('');
  const [senha, setSenha] = useState('');
  const [erro, setErro] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const { login } = useUser();  // Usando login para verificar credenciais
  const navigate = useNavigate();

  const handleLogin = () => {
    if (login(nome, senha)) {  // Verificando credenciais com a função login
      navigate('/game'); // Navega para o jogo se as credenciais estiverem corretas
    } else {
      setErro('Nome de usuário ou senha incorretos.');  // Mostra erro se as credenciais estiverem erradas
    }
  };

  return (
    <div className={styles.containerGeral}>
        <div className={styles.container}>

            <div className={styles.imagemRotatoria}>
                <RotatingImage Image={ImageRotatoria} Largura={50}/>
            </div>

          <div  className={styles.containerInputName}>
              <input
                className={styles.input}
                type="text"
                placeholder="Nome de usuário"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                maxLength={15}
              />
        </div>
          <div  className={styles.containerInputSenha}>
              <input
                className={styles.input}
                type={mostrarSenha ? "text" : "password"} 
                placeholder="Senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />

                <button className={styles.toggleButtonSenha} onClick={() => setMostrarSenha(!mostrarSenha)}>
                  <img className={styles.imgButtonSenha} src={mostrarSenha ? eyeOpen  : eyeClosed } alt={mostrarSenha ? "Senha oculta" : "Senha visível"} />
                </button>
          </div>
      

          <button className={styles.button} onClick={handleLogin}>Entrar no Jogo</button>
          {erro && <p className={styles.error}>{erro}</p>}
          <p className={styles.registerPrompt}>Se ainda não tem uma conta, cadastre-se.</p>
          <button className={styles.buttonCadastrar} onClick={() => navigate('/cadastrar')}>Cadastrar</button>
        </div>
    </div>
  );
}

export default Login;
