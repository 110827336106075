import React from "react";
import styles from "./Loja.module.css";
//import { Link } from "react-router-dom";
import Relogios from "./Produtos/Relogios/Relogios";
import CursosAstronomia from "./Produtos/CursosAstronomia/CursosAstronomia";



//<section className={styles.containerTema}>
//<container className={styles.boxItens}>
//  <nav className={styles.nav}>
   // <Link className={styles.boxTema} to="/relogios">
//      Relógios
//    </Link>
 //   <Link className={styles.boxTema} to="/celulares">
  //    Celulares
 //   </Link>
 //   <Link className={styles.boxTema} to="/cameras">
 //     Câmeras
 //   </Link>
  //</nav>
//</container>
//</section>


const Loja = () => {
  return (
    <div>
   
      <section className={styles.containerProdutosLoja}>
        <CursosAstronomia/>
        {/* <Relogios/> */}

           {/* ================================================= */}
          {/* ==ADS======ADS=======ADS=======ADS==========ADS== */}
          {/* ================================================= */}
          {/* ================================================= */}
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3074013886457000"
     crossorigin="anonymous"></script>
        
          <ins class={styles.adsbygoogle}
              data-ad-client="ca-pub-3074013886457000"
              data-ad-slot="2281195081"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
          <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
          {/* ================================================= */}
          {/* ==ADS======ADS=======ADS=======ADS==========ADS== */}
          {/* ================================================= */}
          {/* ================================================= */}

      </section>
    </div>
  );
};

export default Loja;
