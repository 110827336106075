import React from "react";
import styles from "./Sidebar.module.css";
import { Link } from "react-router-dom";
import useMedia from "../../Hooks/useMedia";
import { useLocation } from "react-router-dom";
import IconHome from "../../Assets/icons-home.svg";

const Sidebar = () => {
  const mobile = useMedia("(max-width: 850px)");
  const [mobileMenu, setMobileMenu] = React.useState(false);

  const { pathname } = useLocation();
  React.useEffect(() => {
    setMobileMenu(false);
  }, [pathname]);

  return (
    <>
      {mobile && (
        <button
          aria-label="Menu"
          className={`${styles.mobileButton} ${
            mobileMenu && styles.mobileButtonActive
          }`}
          onClick={() => setMobileMenu(!mobileMenu)}
        ></button>
      )}

      <nav
        className={`${mobile ? styles.navMobile : styles.containerNav} ${
          mobileMenu && styles.navMobileActive
        }`}
      >
        <Link className={`${styles.button} ${styles.buttonIconHome}`} to="/">
         <img src={IconHome}/>
        </Link>
        <Link className={styles.button} to="/loja">
          Loja
        </Link>
        <Link className={styles.button} to="/ferramentas">
          Ferramentas
        </Link>
        <Link className={styles.button} to="/jogos">
          Jogos
        </Link>
        <Link className={styles.button} to="/sobre">
          Sobre
        </Link>
        {/* <a className={styles.button} href="#direcionar-contato">
          Contatos
        </a> */}
      </nav>
    </>
  );
};

export default Sidebar;
