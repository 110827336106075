import React from 'react'
import Jogo from './Jogo';

function MemoryKunin() {
  return (
    <div>
      <Jogo/>
    </div>
  )
}

export default MemoryKunin;