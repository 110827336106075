import carta0 from './imgs/CartasPeixes/0.jpg';
import carta1 from './imgs/CartasPeixes/1.jpg';
import carta2 from './imgs/CartasPeixes/2.jpg';
import carta3 from './imgs/CartasPeixes/3.jpg';
import carta4 from './imgs/CartasPeixes/4.jpg';
import carta5 from './imgs/CartasPeixes/5.jpg';
import carta6 from './imgs/CartasPeixes/6.jpg';
import carta7 from './imgs/CartasPeixes/7.jpg';


const cartasPeixes = [
  { imagem: carta0 },
  { imagem: carta1 },
  { imagem: carta2 },
  { imagem: carta3 },
  { imagem: carta4 },
  { imagem: carta5 },
  { imagem: carta6 },
  { imagem: carta7 },
];

export default cartasPeixes;
