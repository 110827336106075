import React from "react";
import styles from "./Footer.module.css";
import iconInstagram from "../Assets/icon-instagram.png";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.boxImgP}>
        <p>Fohattech - Direitos reservados.</p>
        <a
          id="direcionar-contato"
          href="https://www.instagram.com/fohattech/"
        >
          <img className={styles.iconInstagram} src={iconInstagram} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
