import React from "react";
import styles from "./Sobre.module.css";
import iconInstagramSobre from "./icon/icon-instagram.png";

const Sobre = () => {
  return (
    <>
      <container className={styles.container}>
        <h1 className={styles.h1}>Fohattech</h1>
        <p className={styles.texto1}>
          Fohattech é um site que oferece uma ampla variedade de produtos
          afiliados, além de disponibilizar ferramentas gratuitas.
        </p>
        <p className={styles.texto2}>
          Descubra as últimas tendências tecnológicas e aproveite os benefícios
          oferecidos por essa plataforma inovadora.
        </p>
        <p className={styles.texto3}>
          Quer ficar por dentro das últimas tendências e novidades no mundo da
          tecnologia? Siga o Fohattech no{" "}
          <a href="https://www.instagram.com/fohattech/">Instagram</a> e
          descubra tudo o que os nossos clientes já estão aproveitando!
        </p>
        <a href="https://www.instagram.com/fohattech/">
          <img className={styles.iconInstagram} src={iconInstagramSobre} />
        </a>
      </container>
    </>
  );
};

export default Sobre;
