
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './UserContext';

const PrivateRoute = ({ children, ...rest }) => {
  const { currentUser } = useUser();


  if (!currentUser) {
    // Redireciona para a página de login se o usuário não estiver autenticado
    return <Navigate to="/login" replace state={{ from: rest.path }} />;
  }

  // Renderiza os componentes filhos se o usuário estiver autenticado
  return children;
};

export default PrivateRoute;
