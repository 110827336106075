import React, { useEffect } from "react";
import "./Game.module.css";
import Usuario from "./Usuario/Usuario";
import MemoryKunin from "./MemoryKunin/MemoryKunin";




function Game() {



  return (
    <div>
        <div className="Game">
          <Usuario/>
          <main className="GameBody">
           <MemoryKunin/>
          </main>
        </div>
    </div>
  );
}

export default Game;
