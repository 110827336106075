import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styles from "./Cadastrar.module.css";
import eyeOpen from '../icons/olhos-aberto.png';
import eyeClosed from '../icons/olhos-fechado.png';
import RotatingImage from '../RotatingImage/RotatingImage';
import ImageRotatoria from '../icons/logo.png';

const Cadastrar = () => {
  const [nome, setNome] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmSenha, setConfirmSenha] = useState('');
  const [erro, setErro] = useState('');
  const { addUser } = useUser();
  const navigate = useNavigate();
  const [mostrarSenha, setMostrarSenha] = useState(false);

  const handleCadastro = () => {
    if (!nome || nome.trim().length === 0 || senha.length < 6 || senha !== confirmSenha) {
      setErro('Preencha todos os campos corretamente.');
      return;
    }

    const newUser = {
      nome,
      senha,
      Carteira: { MoedaKunin: 0, FluidoKunin: 0 },
      Avatar: "",
      Ferramentas: [],
      Mochila: []
    };

    addUser(newUser);
    navigate('/game');
  };

  return (

    <div className={styles.containerGeral}>
      <div className={styles.container}>

        <div className={styles.imagemRotatoria}>
            <RotatingImage Image={ImageRotatoria} Largura={50}/>
        </div>
        <div  className={styles.containerInputName}>
          <input 
            className={styles.input}
            type="text" 
            placeholder="Digite seu nome" 
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            maxLength={15}
          />
        </div>

        <div  className={styles.containerInputSenha}>
            <input 
              className={styles.input}
              type={mostrarSenha ? "text" : "password"} 
              placeholder="Senha (mínimo 6 dígitos)" 
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
              <button className={styles.toggleButtonSenha} onClick={() => setMostrarSenha(!mostrarSenha)}>
                <img className={styles.imgButtonSenha} src={mostrarSenha ? eyeOpen  : eyeClosed } alt={mostrarSenha ? "Senha oculta" : "Senha visível"} />
              </button>
        </div>
        <div  className={styles.containerInputSenha}>
          <input 
            className={styles.input}
            type={mostrarSenha ? "text" : "password"}  
            placeholder="Confirme sua senha" 
            value={confirmSenha}
            onChange={(e) => setConfirmSenha(e.target.value)}
          />
          <button className={styles.toggleButtonSenha} onClick={() => setMostrarSenha(!mostrarSenha)}>
              <img className={styles.imgButtonSenha} src={mostrarSenha ? eyeOpen  : eyeClosed } alt={mostrarSenha ? "Senha oculta" : "Senha visível"} />
            </button>

        </div>
        <button className={styles.button} onClick={handleCadastro}>Cadastrar</button>
        {erro && <p className={styles.error}>{erro}</p>}
      </div>



          {/* ================================================= */}
          {/* ==ADS======ADS=======ADS=======ADS==========ADS== */}
          {/* ================================================= */}
          {/* ================================================= */}
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3074013886457000"
     crossorigin="anonymous"></script>
        
          <ins class={styles.adsbygoogle}
              data-ad-client="ca-pub-3074013886457000"
              data-ad-slot="2281195081"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
          <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
          {/* ================================================= */}
          {/* ==ADS======ADS=======ADS=======ADS==========ADS== */}
          {/* ================================================= */}
          {/* ================================================= */}


    </div>
  );
}

export default Cadastrar;