import React, { Component } from "react";
import Slider from "react-slick";
import styles from "./Relogios.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      rows: 1,
      initialSlide: 0,
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const items = [
      {
      id: 0,
      img: "https://ae01.alicdn.com/kf/S73d2aacc7a07490ba1b210d073a69798l.jpg_640x640Q90.jpg_.webp",
      name: "Watch 9 Series 8",
      valuePricePerX: 47.36,
      pricePerX: "até 2x de",
      price: 92.42,
      description: "Smartwatch impermeável para homens e mulheres, Relógios inteligentes, Rastreamento GPS, NFC, Chamada Bluetooth, Atualização de açúcar no sangue",
      shoppingLink: "https://s.click.aliexpress.com/e/_DDbQzXn",
      logoAfiliado: "/images/icon/logo-aliexpress.png",
       },
      {
        id: 1,
        img: "https://ae01.alicdn.com/kf/Sdd89c432851f44e3b897f3f68abb5d77N/Smart-Watch-Masculino-com-Lip-dios-Sangu-neos-Rastreador-de-Fitness-Frequ-ncia-Card-aca-Chamada.jpg_.webp",
        name: "Smart Watch",
        valuePricePerX: 118.77,
        pricePerX: "até 2x de",
        price: 231.75,
        description: "Com Lipídios Sanguíneos, Rastreador de Fitness, Frequência Cardíaca, Chamada Bluetooth, Monitor de Glicose Sanguínea, ECG e PPG",
        shoppingLink: "https://s.click.aliexpress.com/e/_DBD3N3n",
        logoAfiliado: "/images/icon/logo-aliexpress.png",
      },
      {
        id: 2,
        img: "https://ae01.alicdn.com/kf/S78cad0cec69b4914841f8ccd701a9cebL/UItra-9-Gen-2-Rel-gio-Inteligente-para-Apple-Parafuso-Real-Fixo-Tela-Amoled-49mm-Jogo.jpg_.webp",
        name: "Watch Ultra 9 Gen 2",
        valuePricePerX: 55.76,
        pricePerX: "até 2x de",
        price: 108.80,
        description: "Relógio Inteligente, Parafuso Real Fixo, Tela Amoled 49mm, Jogo ECG, Carga Sem Fio, NFC Pulseira de Fitness, 450mAh, Novo",
        shoppingLink: "https://s.click.aliexpress.com/e/_DCjBVez",
        logoAfiliado: "/images/icon/logo-aliexpress.png",
      },
      
    ];

    return (
      <>
        <section className={styles.containerTema}>
          <h2 className={styles.title}>Relógios</h2>
        </section>

        <section className={styles.vitrineContainer}>
          <Slider {...settings}>
            {items.map((item, key) => {
              return (
                <div className={styles.containerShelf} key={key}>
                  <div className={styles.shelf}>
                    <div className={styles.containerImg}>
                      <img
                        className={styles.img}
                        src={item.img}
                        alt={`${item.name}img`}
                      />
                    </div>
                    <div className={styles.boxNameEscription}>
                      <h3 className={styles.name}>{item.name}</h3>
                      <div className={styles.boxDescriptionVisible}>
                        <p className={styles.description}>{item.description}</p>
                      </div>
                      <a className={styles.button} href={item.shoppingLink}>
                      Saiba Mais
                      <img
                        className={styles.logoAfiliadosClass}
                        src={item.logoAfiliado}
                      />
                    </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </section>
      </>
    );
  }
}
