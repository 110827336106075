import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Vitrine from "./Components/Vitrine/Vitrine";
import Sidebar from "./Components/Sidebar/Sidebar";
import CalculoIMC from "./Components/CalculoIMC/CalculoIMC";
import Sobre from "./Components/Sobre/Sobre";
import Loja from "./Components/Loja/Loja";
import Relogios from "./Components/Loja/Produtos/Relogios/Relogios";
import CursosAstronomia from "./Components/Loja/Produtos/CursosAstronomia/CursosAstronomia";
import Nasa from "./Components/Nasa/Nasa";
import NasaSearch from "./Components/Nasa/NasaSearch";
import Ferramentas from "./Components/Ferramentas/Ferramentas";
import ScrollTop from "./Components/ScrollTop/ScrollTop";
import { UserProvider } from "./Components/Jogos/Login/UserContext";
import Login from "./Components/Jogos/Login/Login";
import Game from "./Components/Jogos/Home/Game/Game";
import Cadastrar from "./Components/Jogos/Login/Cadastrar";
import PrivateRoute from "./Components/Jogos/Login/PrivateRoute";
import HomeGame from "./Components/Jogos/Home/HomeGame";
import Jogos from "./Components/Jogos/Jogos";
// import DONTS from "vanta/src/vanta.dots";

function App() {
  // useEffect(() => {
  //   DONTS({
  //     el: "#vanta",
  //     size: 3.3,
  //     spacing: 40.0,
  //     color: 0xc8a91c,
  //     showLines: false,
  //   });
  // }, []);

  return (
    <div>
      <BrowserRouter>
      <UserProvider>
          <ScrollTop/>
            <Header />
            <div className="App">
              <Sidebar />
              <main className="AppBody">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/jogos" element={ <Jogos/>} />
                  <Route path="/login" element={ <Login/> } />
                  <Route path="/cadastrar" element={ <Cadastrar/> } />
                  <Route path="/Game" element={
                      <PrivateRoute>
                        <Game />
                      </PrivateRoute>
                    } />
                  <Route path="/vitrine" element={<Vitrine />} />
                  <Route path="/loja" element={<Loja />} />
                  <Route path="/calculoimc" element={<CalculoIMC />} />
                  <Route path="/sobre" element={<Sobre />} />
                  <Route path="/relogios" element={<Relogios />} />
                  <Route path="/cursos-astronomia" element={ <CursosAstronomia/> } />
                  <Route path="/nasa-date" element={<Nasa/>} />
                  <Route path="/nasa-search" element={<NasaSearch/>} />
                  <Route path="/ferramentas" element={<Ferramentas/>} />
                </Routes>
              </main>
            </div>
            <Footer />
        </UserProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
