import React from 'react';
import styles from './FimDeFase.module.css';

const FimDeFase = ({ tentativas, cronometro, passou, proximaFase, reiniciarFase }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <h2>Fim de fase</h2>
        <p>Tentativas: {tentativas}</p>
        <p>Cronômetro: {cronometro} s</p>
        {passou ? (
          <>
            <p>Você passou de fase!</p>
            <button onClick={proximaFase}>Próxima Fase</button>
          </>
        ) : (
          <>
            <p>Você não passou de fase.</p>
            <button onClick={reiniciarFase}>Reiniciar Fase</button>
          </>
        )}
      </div>
    </div>
  );
};

export default FimDeFase;
