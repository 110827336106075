import {React,useEffect} from "react";
import { useState, useCallback } from "react";
import styles from "./CalculoIMC.module.css";

import imcImgBaixo from "../../Assets/imcImg/baixo-peso.png";
import imcImgAdequado from "../../Assets/imcImg/peso-adequado.png";
import imcImgSobre from "../../Assets/imcImg/sobre-peso.png";
import imcImgObesidade from "../../Assets/imcImg/obesidade.png";
import {IMCCreatingAffiliateBlock} from "./IMCCreatingAffiliateBlock";

const CalculoIMC = () => {
  const [altura, setAltura] = useState("");
  const [peso, setPeso] = useState("");
  const [resultadoCalculo, setResultadoCalculo] = useState("");
  var [resultado, setResultado] = useState("");
  var [calculou, setCalculou] = useState(false);
  var imc;
  const [resultado2ComStrong, setResultado2ComStrong] = useState("");
  const [VerificarBaixo, setVerificarBaixo] = useState(false);
  const [VerificarAdequado, setVerificarAdequado] = useState(false);
  const [VerificarSobre, setVerificarSobre] = useState(false);
  const [VerificarObesidade, setVerificarObesidade] = useState(false);
  const [VerificarInputs, SetVerificarInputs] = useState(false);
  const [verificaInputAltura, setVerificaInputAltura] = useState(false);
  const [verificaInputPeso, setVerificaInputPeso] = useState(false);
  const [paraVerificarOdirecionamento, setParaVerificarOdirecionamento] =
    useState(false);
  const [alturaParaMostrarNaTela, setAlturaParaMostrarNaTela] = useState("");
  const [pesoParaMostrarNaTela, setPesoParaMostrarNaTela] = useState("");

  const calcularImc = () => {
    if (altura && peso != "") {
      imc = peso / (altura * altura);
      setResultadoCalculo(+imc.toFixed(2));
      classificaImc();
      setCalculou(true);
      setAlturaParaMostrarNaTela(altura);
      setPesoParaMostrarNaTela(peso);
      setAltura("");
      setPeso("");
      SetVerificarInputs(false);
      setVerificaInputAltura(false);
      setVerificaInputPeso(false);
      setParaVerificarOdirecionamento(true);
    } else {
      SetVerificarInputs(true);
      if (altura === "") {
        setVerificaInputAltura(true);
        setParaVerificarOdirecionamento(false);
      } else {
        setVerificaInputAltura(false);
      }
      if (peso === "") {
        setVerificaInputPeso(true);
        setParaVerificarOdirecionamento(false);
      } else {
        setVerificaInputPeso(false);
      }
    }
  };

  const classificaImc = () => {
    if (imc < 18.5) {
      setVerificarBaixo(true);
      setVerificarAdequado(false);
      setVerificarSobre(false);
      setVerificarObesidade(false);
      resultado = "Seu IMC é menor que 18.5, isso quer dizer que você esta ";
      setResultado2ComStrong("abaixo do peso.");
    } else if (imc >= 18.5 && imc < 25) {
      setVerificarBaixo(false);
      setVerificarAdequado(true);
      setVerificarSobre(false);
      setVerificarObesidade(false);
      resultado =
        "Seu IMC está entre 18.5 e 24.9, isso quer dizer que você esta no ";
      setResultado2ComStrong("peso ideal.");
    } else if (imc >= 25 && imc < 29.9) {
      setVerificarBaixo(false);
      setVerificarAdequado(false);
      setVerificarSobre(true);
      setVerificarObesidade(false);
      resultado =
        "Seu IMC está entre 25 e 29.9, isso quer dizer que você esta ";
      setResultado2ComStrong("acima do peso.");
    } else if (imc >= 30) {
      setVerificarBaixo(false);
      setVerificarAdequado(false);
      setVerificarSobre(false);
      setVerificarObesidade(true);
      resultado =
        "Seu IMC é maior que 30, isso quer dizer que você está em niveis. ";
      setResultado2ComStrong("obesidade.");
    }

    setResultado(resultado);
    return resultado;
  };

  if (calculou === true) {
    var retornoImc = "Seu IMC é:" + " " + resultadoCalculo;
    var retornoClassificacao = resultado;
  }

  const handleKeyUpAltura = useCallback((e) => {
    e.currentTarget.maxLength = 4;
    let valueRegexAltura = e.currentTarget.value;
    valueRegexAltura = valueRegexAltura.replace(/^[a-z]/, "");
    valueRegexAltura = valueRegexAltura.replace(/^(\d{1})(\d)/, "$1.$2");
    e.currentTarget.value = valueRegexAltura;
    setAltura(valueRegexAltura);
  }, []);

  const handleKeyUpPeso = useCallback((e) => {
    e.currentTarget.maxLength = 6;
    let valueRegexPeso = e.currentTarget.value;
    valueRegexPeso = valueRegexPeso.replace(/^[a-z]/, "");
    e.currentTarget.value = valueRegexPeso;
    setPeso(valueRegexPeso);
  }, []);

  useEffect(() => {
    AtivarBlocoAfiliadosIMC();
 },[]);

 
  function AtivarBlocoAfiliadosIMC() {
    const blocoAFDimc = document.getElementById('BoxAfiliadosId');
    blocoAFDimc.innerHTML = IMCCreatingAffiliateBlock();
 }

  return (
    <section className={styles.calculoImcContainer}>
      <div className={styles.boxcalculoImc}>
        <div className={styles.boxParaFundo}>
          <h3>Calcular seu IMC</h3>
          <br />
          <label>Altura</label>
          <input
            className={`${styles.inputAltura} ${
              verificaInputAltura && styles.colorInputAltura
            }`}
            name="altura"
            placeholder="Ex: 1,70"
            onKeyUp={handleKeyUpAltura}
            value={altura.replace(",", ".")}
            onChange={(e) => setAltura(e.target.value)}
          />
          <br />
          <label>Peso</label>
          <input
            className={`${styles.inputPeso} ${
              verificaInputPeso && styles.colorInputPeso
            }`}
            placeholder="Ex: 65"
            name="peso"
            onKeyUp={handleKeyUpPeso}
            value={peso.replace(",", ".")}
            onChange={(e) => setPeso(e.target.value)}
          />
          {VerificarInputs && (
            <p className={styles.VerificacaoInput}>Preencha todos os campos!</p>
          )}

          <a
            href="#direcionar"
            id="idDoButton"
            className={styles.btnCalcular}
            onClick={calcularImc}
          >
            Calcular
          </a>

          <div className={styles.boxIMGs}>
            <img className={styles.imcImg} src={imcImgBaixo} />
            <img className={styles.imcImg} src={imcImgAdequado} />
            <img className={styles.imcImg} src={imcImgSobre} />
            <img className={styles.imcImg} src={imcImgObesidade} />
          </div>

          <div className={styles.containerResulte}>
            <div
              className={`${styles.boxInfo} ${
                VerificarBaixo && styles.colorBaixo
              } ${VerificarAdequado && styles.colorAdequado} ${
                VerificarSobre && styles.colorSobre
              } ${VerificarObesidade && styles.colorObesidade}`}
            >
              <h4>{retornoImc}</h4>
              <p>
                {retornoClassificacao} <strong>{resultado2ComStrong}</strong>
              </p>
            </div>
            <div className={styles.boxImg}>
              {paraVerificarOdirecionamento && <div id="direcionar"></div>}
              {VerificarBaixo && (
                <img className={styles.imcImg} src={imcImgBaixo} />
              )}
              {VerificarAdequado && (
                <img className={styles.imcImg} src={imcImgAdequado} />
              )}
              {VerificarSobre && (
                <img className={styles.imcImg} src={imcImgSobre} />
              )}
              {VerificarObesidade && (
                <img className={styles.imcImg} src={imcImgObesidade} />
              )}
              <div className={styles.boxValueAlturaEpeso}>
                <div className={styles.boxValueAltura}>
                  {paraVerificarOdirecionamento && (
                    <>
                      <strong>{alturaParaMostrarNaTela}</strong>
                      <p>h</p>
                    </>
                  )}
                </div>
                <div className={styles.boxValuePeso}>
                  {paraVerificarOdirecionamento && (
                    <>
                      <strong>{pesoParaMostrarNaTela}</strong>
                      <p>kg</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

          {/* ================================================= */}
          {/* ==ADS======ADS=======ADS=======ADS==========ADS== */}
          {/* ================================================= */}
          {/* ================================================= */}

          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3074013886457000"
              crossorigin="anonymous"></script>
     
          <ins class={styles.adsbygoogle}
              data-ad-client="ca-pub-3074013886457000"
              data-ad-slot="9185514781"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
          <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
          </script>

          {/* ================================================= */}
          {/* ==ADS======ADS=======ADS=======ADS==========ADS== */}
          {/* ================================================= */}
          {/* ================================================= */}

      <div className={styles.containerAfiliados}>
            <div id="BoxAfiliadosId" className={styles.BoxAfiliados}></div>
      </div>
    </section>
  );
};

export default CalculoIMC;
