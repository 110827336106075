export function IMCCreatingAffiliateBlock() {
 
    return(`

    <div class="Block-containerAFDs">
      <h3 class="class-cursos-h3">Quer melhorar sua saúde física?</h3>
      <p class="class-esplicando">Aqui está uma lista de conteúdos que pode ajudar você!</p>
      <div class="container-itens">
      
            <a href="https://go.hotmart.com/C90071053F"> 
                <div class="card-item">
                  <img src="https://static-media.hotmart.com/6FHq65HMlLTffFHqQ1JroBZuvu0=/filters:background_color(white)/hotmart/product_pictures/2dd3db61-6332-4caa-b849-9e40a809141e/EMAGRECA1.png"/>
                  <p>Mais saúde - controle sua diata, bem-estar físico.</p>
                </div>
            </a>
      
            <a href="https://go.hotmart.com/K90071115C?dp=1"> 
                <div class="card-item">
                  <img src="https://static-media.hotmart.com/AIwocuD89ZI_s6VyJMj5rhom15A=/filters:background_color(white)/hotmart/product_pictures/370b12a8-0e59-4ed7-aba2-79e46943d0f3/TNT11.png"/>
                  <p>TNT - Módulos. Treino para quem Não Treina.</p>
                </div>
            </a>
      
            <a href="https://go.hotmart.com/K90071314I">
                <div class="card-item">
                  <img src="https://static-media.hotmart.com/rhjaIBfNWZe3QmOclt6QQ9fAT9U=/filters:background_color(white)/hotmart/product_pictures/861994d3-9024-464e-93e3-fcebba3db2c1/CAPAMOBILIDADECopia2.jpg"/>
                  <p>Treinos de Mobilidade - Treine Sem Personal.</p>
                </div>
            </a>
      
            <a href="https://go.hotmart.com/M90071211K">
                <div class="card-item">
                  <img src="https://static-media.hotmart.com/32oM08A064EaH2Iqih--TeY9Kdo=/filters:background_color(white)/hotmart/product_pictures/602a5530-1507-457f-a639-d1877472b268/PostparaInstagramSeumaiorProjetoevoceParaacademiasePersonalTrainer1.png"/>
                  <p>TREINO HARD - Módulos. TREINO FEMININO AVANÇADO.</p>
                </div>
            </a>
      
            <a href="https://go.hotmart.com/O90071261U">
                <div class="card-item">
                  <img src="https://static-media.hotmart.com/dZB_BfqUQiMguvjOz1zUOLjajm8=/filters:background_color(white)/hotmart/product_pictures/846dda62-7eba-4d60-b530-15bc9bcfd8be/Capadeebooksaladanopotemodernonatural.png"/>
                  <p>PRÉ TREINO E PÓS TREINO - O ebook completo.</p>
                </div>
            </a>
      
          <a href="https://go.hotmart.com/W90071409A">
              <div class="card-item">
                <img src="https://static-media.hotmart.com/GfTIO0ItljY3CGVgRA-6rhHbcGs=/filters:background_color(white)/hotmart/product_pictures/3fc7801d-f32a-4d3b-9f65-0f51d8dae9cb/1_20230712_112142_0000.png"/>
                <p>Treino fácil - um treino para cada dia da semana.</p>
              </div>
          </a>
      
      </div>
</div>    
`); 
}