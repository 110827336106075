import React, {useEffect, useState} from 'react';
import styles from "./Nasa.module.css";
import {CreatingAffiliateBlock} from "./CreatingAffiliateBlock";

const Nasa = () => {

const getDate = async () => {

    const date = document.getElementById("date").value
    const key = "D9GIJ6MxFnCTbRZt6NP9sPJYvXljNi2sQIZQ0vix";
    const url = `https://api.nasa.gov/planetary/apod?api_key=${key}&date=${date}`;

    const respode = await fetch(url);
    const dados = await respode.json();
    return update(dados);
}

const update = (dados) => {

    document.getElementById("pic_date").innerHTML = dados.date;
    document.getElementById("title").innerHTML = dados.title;
    document.getElementById("apod_pic").src = dados.url;
    document.getElementById("caption").innerHTML = dados.explanation;
    return dados; 
}

useEffect(() => {
   getDate();
   AtivarLoading();
   AtivarBlocoAfiliados()
},[]);


const [isLoading, setLoading] = useState(null);

function AtivarLoading() {
  setLoading(true);
  setTimeout(() => {
    setLoading(false);
  },500);
}

function AtivarBlocoAfiliados() {
   const blocoAFD = document.getElementById('BoxAfiliados')
   blocoAFD.innerHTML = CreatingAffiliateBlock();
}

  return (
    <>
      <div className={styles.container}>
        <div className={styles.boxCard}>
          <h2>Foto astronômica do dia</h2>
          <p className={styles.classPnasa}>Descubra agora qual foi a imagem que a NASA fotografou no dia do seu nascimento!</p>
            <div  className={styles.boxSerch} id="serch">
              <input type="date" id="date"/>
              <button onClick={getDate}>Buscar</button>
            </div>

        {isLoading &&
              <div className={styles.imagesContainerLoading}>
                <div className={styles.loading}><div className={styles.loadingEsfera}></div></div>
              </div> 
        } 

            <div className={styles.cardSearch}>
              <em className={styles.date} id="pic_date"/>
              <h3 className={styles.title} id="title"/>
              <div>
                  <img src="" alt="" id="apod_pic" className={styles.img}/>
              </div>
              <p className={styles.text} id="caption"/>   
            </div>
            <div id='BoxAfiliados'></div>
        </div>
      </div>
    </>
  )
}

export default Nasa;
