import React from 'react';
import styles from './RotatingImage.module.css'; // Importe o arquivo CSS para estilização


const RotatingImage = ({Image,Largura, }) => {
  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        src={Image}
        alt="Imagem 3D giratória"
        width={Largura}
      />
    </div>
  );
};

export default RotatingImage;