import React, { useContext } from 'react';
import { UserProvider, useUser } from '../../../Login/UserContext'; // Corrigindo o caminho de importação
import styles from './Usuario.module.css'; // Importando o arquivo de estilos
import AvatarSemFoto from '../../../imgs/Avatares/perfil-sem-foto.png'
import IconCarteira from '../../../icons/carteira.png'

const Usuario = () => {
  const { currentUser } = useUser(); // Usando useUser() em vez de useContext(UserContext)

  if (!currentUser) {
    return <div>Usuário não autenticado</div>;
  }

  const { nome, Carteira, Avatar } = currentUser;
  const { MoedaKunin } = Carteira;

  const handleClick = () => {
    // Coloque aqui o código para navegar para a outra rota
  };

  return (
    <div className={styles['usuario-container']}>

        <div className={styles.containerAvatarNome}>
            <div className={styles.avatar}>
                  <img src={AvatarSemFoto} alt="Avatar do usuário" />
                  <button onClick={handleClick}>Editar</button>
            </div>
            <div className={styles['nome-usuario']}>
                    <span>{nome}</span>
            </div>
        </div>

            <div className={styles.info}>
              <div className={styles.carteira}>
                <img className={styles.carteiraImg} src={IconCarteira} alt="Avatar do usuário" />
                <span className={styles.valorCarteira}>{MoedaKunin}</span>
              </div>
            </div>

    </div>
  );
};

export default Usuario;