import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import styles from "./Ferramentas.module.css";
import LogoNasa from "../../Assets/logo-nasa.png";
import imcImgBaixo from "../../Assets/imcImg/baixo-peso.png";
import imcImgAdequado from "../../Assets/imcImg/peso-adequado.png";
import imcImgSobre from "../../Assets/imcImg/sobre-peso.png";
import imcImgObesidade from "../../Assets/imcImg/obesidade.png";
const Ferramentas = () => {
  return (
    <>
     <div className={styles.container}>
        <div className={styles.boxFerramentas}>
        <Link className={styles.LinkFerramenta} to="/nasa-search">
              NASA
          <img className={styles.imgLogoNasa} src={LogoNasa} />
        </Link>
        <Link className={styles.LinkFerramenta} to="/calculoimc">
            Cálculo do IMC
          <div className={styles.boxIMGs}>
            <img className={styles.imcImg} src={imcImgBaixo} />
            <img className={styles.imcImg} src={imcImgAdequado} />
            <img className={styles.imcImg} src={imcImgSobre} />
            <img className={styles.imcImg} src={imcImgObesidade} />
          </div>
        </Link>

        </div>

          {/* ================================================= */}
          {/* ==ADS======ADS=======ADS=======ADS==========ADS== */}
          {/* ================================================= */}
          {/* ================================================= */}
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3074013886457000"
     crossorigin="anonymous"></script>
        
          <ins class={styles.adsbygoogle}
              data-ad-client="ca-pub-3074013886457000"
              data-ad-slot="2281195081"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
          <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
          {/* ================================================= */}
          {/* ==ADS======ADS=======ADS=======ADS==========ADS== */}
          {/* ================================================= */}
          {/* ================================================= */}
        
     </div>
    </>
  )
}

export default Ferramentas;