import React, { Component } from "react";
import Slider from "react-slick";
import styles from "./CursosAstronomia.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      rows: 1,
      initialSlide: 0,
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const items = [
      {
      id: 0,
      img: "https://static-media.hotmart.com/obx_o4zVDJ-jwcb0cY-3mY-86X8=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/9b2da8d3-e0df-468a-b9e0-680d29bfc5f6/foto_gravacao.jpg?w=920",
      name: "Astronomia Fundamental",
      valuePricePerX: 47.36,
      pricePerX: "até 2x de",
      price: 92.42,
      description: "O curso de Astronomia Fundamental e Essencial é indicado para alunos do ensino fundamental, médio e superior...",
      shoppingLink: "https://go.hotmart.com/L89676324N",
      logoAfiliado: "/images/icon/logo-hotmart.png",
       },
      {
        id: 1,
        img: "https://static-media.hotmart.com/dI9DXnN6OiUG-C4bUg8lzTDURtI=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/45c31089-d829-4700-94c7-e1ab4a89100d/Designsemnome20220912T134020777.png?w=920",
        name: "Orbitando O Sistema Solar",
        valuePricePerX: 118.77,
        pricePerX: "até 2x de",
        price: 231.75,
        description: "É um livro digital (em pdf) que vai explicar e detalhar para você sobre todos os planetas o sistema solar, assim como também os principais...",
        shoppingLink: "https://go.hotmart.com/Q89676974J",
        logoAfiliado: "/images/icon/logo-hotmart.png",
      },
      {
        id: 2,
        img: "https://static-media.hotmart.com/PmqxVyk8-IqZDNkctHDKRgxMmnk=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/b01cb133-f575-4fcc-b8bf-9d54d9a1affe/ebook.png?w=920",
        name: "O guia pratico da ufologia",
        valuePricePerX: 55.76,
        pricePerX: "até 2x de",
        price: 108.80,
        description: "Um Almanaque da Ufologia que te levará em uma viagem na história da nossa sociedade desde seus primórdios até os dias atuais...",
        shoppingLink: "https://go.hotmart.com/M89677050G",
        logoAfiliado: "/images/icon/logo-hotmart.png",
      },
      {
        id: 3,
        img: "https://static-media.hotmart.com/aUVz8-b0O-SFChCEgoQKZT-xpcI=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/5552b2c8-d421-4f0e-ab73-1d74b1506862/cinco.png?w=920",
        name: "viagem pelo infinito...",
        valuePricePerX: 0,
        pricePerX: "até 2x de",
        price: 0,
        description: "Abordamos os tópicos assuntos da astronomia, mas sem aquele jeito todo formal chato, e sim de um jeito descontraido...",
        shoppingLink: "https://go.hotmart.com/F89677003P",
        logoAfiliado: "/images/icon/logo-hotmart.png",
      },
      {
        id: 4,
        img: "https://static-media.hotmart.com/RbuNNhm0Ia491_faKmnkZJK-2F4=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/72b19fd4-893d-4836-af3a-95baeec8d10d/CapaINTRODUCAOAASTRONOMIAAMADORAEASTROFOTOGRAFIA1.png?w=920",
        name: "INTRODUÇÃO À ASTRONOMIA...",
        valuePricePerX: 0,
        pricePerX: "até 2x de",
        price: 0,
        description: "Descubra o Universo com nosso Curso de Astronomia Amadora e Astrofotografia!",
        shoppingLink: "https://go.hotmart.com/X89681008F",
        logoAfiliado: "/images/icon/logo-hotmart.png",
      },
      {
        id: 5,
        img: "https://static-media.hotmart.com/8BaD27bZXyJC2ds0Vpw1LU1xA2I=/filters:background_color(white)/hotmart/product_pictures/3658f68f-ed73-4347-8e3d-d17d0b3fa3a1/20230522_153613_0000.png",
        name: "Descomplicando a...",
        valuePricePerX: 0,
        pricePerX: "até 2x de",
        price: 0,
        description: "Descomplicando a Astronomia. Conhecendo um pouco mais sobre o nosso universo de forma completa e descomplicada.",
        shoppingLink: "https://go.hotmart.com/O89679885O",
        logoAfiliado: "/images/icon/logo-hotmart.png",
      },
      // {
      //   id: ,
      //   img: "",
      //   name: "",
      //   valuePricePerX: 0,
      //   pricePerX: "até 2x de",
      //   price: 0,
      //   description: "",
      //   shoppingLink: "",
      //   logoAfiliado: "/images/icon/logo-hotmart.png",
      // },
    ];

    return (
      <>
        <section className={styles.containerTema}>
          <h2 className={styles.title}>Cursos Ligado a Astronomia</h2>
        </section>

        <section className={styles.vitrineContainer}>
          <Slider {...settings}>
            {items.map((item, key) => {
              return (
                <div className={styles.containerShelf} key={key}>
                  <div className={styles.shelf}>
                    <div className={styles.containerImg}>
                      <img
                        className={styles.img}
                        src={item.img}
                        alt={`${item.name}img`}
                      />
                    </div>
                    <div className={styles.boxNameEscription}>
                      <h3 className={styles.name}>{item.name}</h3>
                      <div className={styles.boxDescriptionVisible}>
                        <p className={styles.description}>{item.description}</p>
                      </div>
                      <a className={styles.button} href={item.shoppingLink}>
                      Saiba Mais
                      <img
                        className={styles.logoAfiliadosClass}
                        src={item.logoAfiliado}
                      />
                    </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </section>
      </>
    );
  }
}
