import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import Logo from "../Assets/logo-futuretechpng.png";
import LogoNasa from "../Assets/logo-nasa.png";

const Header = () => {
  const [activeLogoNasa, setActiveLogoNasa] = useState(null);
  const refOne = useRef(null);
  useEffect(() => {
    document.addEventListener("click", ActiveLogoNasa);
    ActiveLogoNasa();
   },[]); 
 
 function ActiveLogoNasa() {
  const url = window.location.href
  const rota = url.split("/").length
  const paramUrl = url.split("/")[rota-1]
  if(paramUrl === 'nasa-search') {
    setActiveLogoNasa(true); 
  }else {
      setActiveLogoNasa(false)
  }
 }

  return (
    <header ref={refOne} className={styles.header}>
      <nav className={`${styles.nav} container`}>
        <Link className={styles.iconHome} to="/" aria-label="Icon - Home">
          <img className={styles.imgLogo} src={Logo} />
        </Link>
        {activeLogoNasa && <Link className={styles.iconHeaderLogoNasa} to="/nasa-search" aria-label="Icon - Home"><img className={styles.imgLogoNasa} src={LogoNasa} /></Link>}

        {/* <div className={styles.containerButtonEimg}>

         </div> */}
      </nav>
    </header>
  );
};

export default Header;
